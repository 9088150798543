.App{
  justify-content: center;
  text-align: center;
  align-items: center;
  width: 100%;
  height: 100%;
}
.inner-container{
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-weight: bolder;
  font-size: 100px;
  color: #5415f1;
  width: 100%;
  height: 100vh;
}
.Vector-Image{
  display: block;
    height: 500px;
    width: 500px;
    align-items: center;
    justify-content: center;
}
