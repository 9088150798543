.Navbar-conatciner{
    display: flex;
    height: 100px;
    width: 100%;
    align-items: center;
    justify-content: center;
}
.Logo-container{
    display: flex;
    height: 98px;
    width: 100%;
    align-items: center;
    justify-content: center;
}
.Logo-Image{
    display: block;
    height: 98px;
    width: 250px;
    align-items: center;
    justify-content: center;
}